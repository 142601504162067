import moment from "moment";
import { MODULE_LIST } from "constants/common";

export function getError(error, message = "Something went wrong!") {
    return error?.response?.data?.message || message;
}

export function formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
}

export function formatDateTime(date) {
    return moment(date).format("YYYY-MM-DD HH:MM:SS");
}

export function sortByDate(dataArr) {  
    console.log(dataArr);
    const sorted = dataArr.sort((a,b) => {
        return moment(a.created_at) - moment(b.created_at)
    })
    console.log(sorted);
    return sorted;
}

export function getModules (bitValue) {
    const modules = MODULE_LIST;
    const accessibleModules = [];
    modules.filter((module, index) => {
        if (bitValue[index] === '1') {
            accessibleModules.push(module);
        }
        return null;
    })
    return accessibleModules
}

export function toSnakeCase(str) {
    return str
        .toLowerCase()
        .replace(/\s+/g, '_')
        .replace(/[^a-z0-9_]/g, '');
}

export function stripBraces(str) {
    if (str) {
        return str.replace(/^{/, '').replace(/}$/, '').replace(/^"|"$/g, '');
    };
};

export function convertPgArray(str) {
    if (str) {
        return str.replace(/^{/, '').replace(/}$/, '').replace(/^"|"$/g, '').split(',');
    } else {
        return [];
    };
};

export function generateRandomColor() {
    return '#' + Math.floor(Math.random()*16777215).toString(16);
}

export function debounce(func, wait) {
    let timeout;
    const debouncedFn = function(...args) {
        const later = () => {
            clearTimeout(timeout);
            func.apply(this, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
    debouncedFn.cancel = () => clearTimeout(timeout);
    return debouncedFn;
}