import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Stack, Spinner, Container } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux";
import { getVoter, updateVoterAction } from "actions/voterActions.js";
import { variableSelector } from "selectors/variableSelector";
import { formatDate, formatDateTime } from "helpers";
import { v4 as uuidv4 } from "uuid";
import { voterSelector } from "selectors/voterSelector";
import { useGeopoliticalUnits } from "hooks/useGeopoliticalUnits";
import { getBarangays, getDistricts, getMuniCities, getPrecincts, getProvinces } from "actions/geopoliticalUnitActions";
import { getVariableListAction } from "actions/variableActions";

function VoterModal(props) {
    const { onHide, show, voterId } = props;
    const [formData, setFormData] = useState({
        province: '',
        district: '',
        municity: '',
        barangay: '',
        precinct: ''
    });
    const { barangays, districts, muniCities, precincts, provinces } = useGeopoliticalUnits({});
    const { variables } = useSelector(variableSelector);
    
    const { isLoading, voter } = useSelector(voterSelector);
    const dispatch = useDispatch();

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    };

    const handleClose = () => {
        setFormData({});
        onHide();
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        dispatch(updateVoterAction(voter.id, formData));
        handleClose();
    };

    const handleVoterDataChange = (e) => {
        if (voter) {
            const voterData = formData.latest_interview?.responses || [];
            const responseIndex = voterData.findIndex((response) => (response.variable_id === e.target.name))
            
            if (responseIndex !== -1) {
                voterData[responseIndex] = { variable_id: e.target.name, answer: e.target.value };
            } else {
                voterData.push({ variable_id: e.target.name, answer: e.target.value });
            }
            
            setFormData((prev) => ({
                ...prev,
                latest_interview: {
                    ...prev['latest_interview'],
                    responses: voterData
                }
            }));
        }
    }

    useEffect(() => {
        dispatch(getVoter(voterId));
    }, [dispatch, voterId]);

    useEffect(() => {
        setFormData(voter);
    }, [voter]);

    useEffect(() => {
        dispatch(getProvinces());
        dispatch(getDistricts());
        dispatch(getMuniCities());
        dispatch(getBarangays());
        dispatch(getPrecincts());
        dispatch(getVariableListAction());
    }, [dispatch]);

    return (
        <Modal onHide={handleClose} show={show} size="xl" animation>
            <Modal.Header closeButton>Voter Profile</Modal.Header>
            <Modal.Body>
                <Container>
                    <Form onSubmit={handleSubmit}>
                        <h4>Basic Information</h4>
                        <Stack gap={2} className="mb-3">
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        name="name"
                                        onChange={handleChange}
                                        defaultValue={formData?.name}
                                        required
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Sex</Form.Label>
                                    <Form.Select
                                        id="sex"
                                        name="sex"
                                        onChange={handleChange}
                                        value={formData?.sex}
                                        required
                                        disabled={voterId && formData?.sex}
                                    >
                                        <option value="">Select sex</option>
                                        {['M', 'F'].map((value) => (
                                            <option key={uuidv4()} value={value}>
                                                {value === 'M' ? 'Male' : 'Female'}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a sex.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Birthdate</Form.Label>
                                    <Form.Control
                                        id="birthdate"
                                        name="birthdate"
                                        type="date"
                                        onChange={handleChange}
                                        defaultValue={formatDate(formData?.birthdate)}
                                        required
                                        
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a birthdate.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <Form.Group>
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    name="address"
                                    onChange={handleChange}
                                    defaultValue={formData?.address}
                                    required
                                    
                                />
                                <Form.Control.Feedback type="invalid">
                                    Please provide an address.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Province</Form.Label>
                                <Form.Select
                                    name="province"
                                    onChange={handleChange}
                                    value={formData?.province}
                                    required
                                    
                                >
                                    <option value="">Select province</option>
                                    {provinces.map((province) => (
                                        <option key={uuidv4()} value={province}>
                                            {province}
                                        </option>
                                    ))}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Please select a province.
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Row>
                                <Form.Group as={Col}>
                                    <Form.Label>District</Form.Label>
                                    <Form.Select
                                        name="province"
                                        onChange={handleChange}
                                        value={formData?.district}
                                        
                                    >
                                        <option value="">Select province</option>
                                        {districts.map((district) => (
                                            <option key={uuidv4()} value={district}>
                                                {district}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a district.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Municipality</Form.Label>
                                    <Form.Select
                                        name="municipality"
                                        onChange={handleChange}
                                        value={formData?.municity}
                                        required
                                        
                                    >
                                        <option value="">Select municipality</option>
                                        {muniCities.map((municipality) => (
                                            <option key={uuidv4()} value={municipality}>
                                                {municipality}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a municipality.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Barangay</Form.Label>
                                    <Form.Select
                                        name="barangay"
                                        onChange={handleChange}
                                        value={formData?.barangay}
                                        required
                                        
                                    >
                                        <option value="">Select barangay</option>
                                        {
                                            barangays.map((barangay) => (
                                                <option key={uuidv4()} value={barangay}>{barangay}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a barangay.
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col}>
                                    <Form.Label>Precinct</Form.Label>
                                    <Form.Select
                                        name="precinct"
                                        onChange={handleChange}
                                        value={formData?.precinct}
                                        required
                                        
                                    >
                                        <option value="">Select precinct</option>
                                        {
                                            precincts.map((precinct) => (
                                                <option key={uuidv4()} value={precinct}>{precinct}</option>
                                            ))
                                        }
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a precinct.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Row>
                            <hr />
                            <Row>
                                
                            </Row>
                        </Stack>
                        <h4>Variable Data</h4>
                        {voter?.latest_interview && <small className="text-muted">Last updated: {formatDateTime(voter.latest_interview.submitted_at)}</small>}
                        <Row>
                        {
                            variables.map((variable) => (
                                <Form.Group as={Col}>
                                    <Form.Label>{variable.name}</Form.Label>
                                    <Form.Control
                                        name={variable.id}
                                        onBlur={handleVoterDataChange}
                                        required
                                        defaultValue={voter?.latest_interview?.responses?.find((response) => (response.variable_id === variable.id))?.answer}
                                        disabled={!formData}
                                        placeholder={!formData ? 'Loading...' : ''}
                                    />
                                </Form.Group>
                            ))
                        }
                        </Row>                        
                        <Modal.Footer>
                            <Button variant="success" type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            className="me-2"
                                        />
                                        <span>Saving...</span>
                                    </>
                                ) : (
                                    'Save'
                                )}
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Container>
            </Modal.Body>
        </Modal>
    )
}

export default VoterModal