import getURL from "api/config";
import { DELETE, GET, POST } from "api/index";
import * as types from "constants/actionTypes";
import { MULTIPART_HEADERS } from "constants/http";
import { getError } from "helpers";

export function getUploadListAction() {
    return function action(dispatch) {
        const url = getURL("GET_UPLOAD_LIST");
        const request = GET(url);

        dispatch({
            type: types.UPLOAD_LIST_LOAD_REQUEST,
            payload: { uploads: [] }
        });

        request.then((response) => {
            const uploads = response?.data?.data?.data || [];

            dispatch({
                type: types.UPLOAD_LIST_LOAD_SUCCESS,
                payload: { uploads }
            })
        }, (error) => {
            dispatch({
                type: types.UPLOAD_LIST_LOAD_FAILED,
                payload: {
                    errors: getError(error)
                }
            });
        });   
    };
};

export function uploadAction(payload) {
    return function action(dispatch) {
        const url = getURL("UPLOAD_FILE");
        const request = POST(url, payload, MULTIPART_HEADERS);

        request.then((response) => {
            const upload = response?.data?.data || {};
            dispatch({
                type: types.UPLOAD_FILE_SUCCESS,
                payload: { upload }
            })
        }, (error) => {
            dispatch({
                type: types.UPLOAD_FILE_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        })
    }
};

export function deleteUploadAction(id) {
    return function action(dispatch) {
        const url = getURL("DELETE_UPLOAD")(id);
        const request = DELETE(url);

        request.then((response) => {
            const upload = response?.data?.data || {};
            dispatch({
                type: types.DELETE_FILE_SUCCESS,
                payload: { upload }
            });
        }, (error) => {
            dispatch({
                type: types.DELETE_FILE_FAILED,
                payload: { errors: getError(error) }
            })
        })
    }
}