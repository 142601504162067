import './Login.css';
import { Button, Container, Form, Stack, Spinner, InputGroup } from "react-bootstrap";
import { useEffect, useState } from "react";
import AlertMessage from "../../components/AlertMessage/AlertMessage";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginAction } from "actions/sessionActions";
import { updateUserVoterIdAction } from "actions/userActions";
import { sessionSelector } from "selectors/sessionSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const [formData, setFormData] = useState({
        username: null,
        password: null
    });
    const [alertConfig, setAlertConfig] = useState({
        heading: "",
        message: "",
        variant: "danger",
        show: false
    });
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { sessionUser, error } = useSelector(sessionSelector);

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    };

    const handleAlertClose = () => {
        setAlertConfig((prev) => ({ ...prev, show: false }));
    };

    const loginUser = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        await dispatch(loginAction(formData.username, formData.password));
        setIsLoading(false);
    };

    useEffect(() => {
        if (sessionUser) {
            navigate('/cms/');
            dispatch(updateUserVoterIdAction(sessionUser.id));
        }
    }, [sessionUser, navigate, dispatch]);

    useEffect(() => {
        if (error) {
            console.log(error);
            setAlertConfig({
                heading: "Login Error",
                message: error,
                variant: "danger",
                show: true
            });
        }
    }, [error]);

    return (
        <section id="login">
            <AlertMessage
                heading={alertConfig.heading}
                message={alertConfig.message}
                variant={alertConfig.variant}
                visibility={alertConfig.show}
                onClose={handleAlertClose}
            />
            <Container className="mt-5">
                <Form className="p-5 bg-light rounded" onSubmit={loginUser}>
                    <Stack gap={3}>
                        <Form.Group>
                            <Form.Label>Username</Form.Label>
                            <Form.Control name="username" placeholder="Username" onChange={handleChange} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Password</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    name="password"
                                    placeholder="Password"
                                    type={showPassword ? "text" : "password"}
                                    onChange={handleChange}
                                />
                                <Button
                                    variant="outline-secondary"
                                    onClick={() => setShowPassword(!showPassword)}
                                >
                                    <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                </Button>
                            </InputGroup>
                        </Form.Group>
                        <Stack gap={2}>
                            <Button type="submit" disabled={isLoading}>
                                {isLoading ? (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {' '}Loading...
                                    </>
                                ) : (
                                    'Login'
                                )}
                            </Button>
                            <Button variant="secondary" onClick={() => navigate('/forgot-password')}>Forgot Password</Button>
                        </Stack>
                    </Stack>
                </Form>
            </Container>
        </section>
    )
}

export default Login;