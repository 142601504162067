import getURL from 'api/config';
import { GET, POST } from 'api/index';
import {
    GET_CHAT_LIST_FAILED,
    GET_CHAT_LIST_REQUEST,
    GET_CHAT_LIST_SUCCESS,
    GET_CHAT_MESSAGES_FAILED,
    GET_CHAT_MESSAGES_REQUEST,
    GET_CHAT_MESSAGES_SUCCESS,
    START_CHAT_FAILED,
    START_CHAT_REQUEST,
    START_CHAT_SUCCESS,
    SEND_CHAT_MESSAGE_FAILED,
    SEND_CHAT_MESSAGE_REQUEST,
    SEND_CHAT_MESSAGE_SUCCESS
} from 'constants/actionTypes';
import { getError } from 'helpers';

export function getChats() {
    return function action(dispatch) {
        const url = getURL('GET_CHAT_LIST');
        const request = GET(url);

        dispatch({
            type: GET_CHAT_LIST_REQUEST,
            payload: { chats: [] }
        });

        request.then((response) => {
            const chats = response?.data?.data;

            dispatch({
                type: GET_CHAT_LIST_SUCCESS,
                payload: { chats }
            })
        }, (error) => {
            dispatch({
                type: GET_CHAT_LIST_FAILED,
                payload: getError(error)
            });
        });
    };
}

export function getChatMessages(id) {
    return function action(dispatch) {
        const url = getURL('GET_CHAT_MESSAGES_LIST')(id);
        const request = GET(url);

        dispatch({
            type: GET_CHAT_MESSAGES_REQUEST,
            payload: { messages: [] }
        });

        request.then((response) => {
            const messages = response?.data?.data;

            dispatch({
                type: GET_CHAT_MESSAGES_SUCCESS,
                payload: { messages }
            })
        }, (error) => {
            dispatch({
                type: GET_CHAT_MESSAGES_FAILED,
                payload: getError(error)
            });
        });
    };
}

export function startChat(data) {
    return function action(dispatch) {
        const url = getURL('START_CHAT');
        const request = POST(url, data);

        dispatch({
            type: START_CHAT_REQUEST
        });

        request.then(() => {
            dispatch({
                type: START_CHAT_SUCCESS
            });
        }, (error) => {
            dispatch({
                type: START_CHAT_FAILED,
                payload: getError(error)
            });
        });
    }
}

export function sendMessage(chatId, data) {
    return function action(dispatch) {
        const url = getURL('SEND_CHAT_MESSAGE')(chatId);
        console.log(url);
        const request = POST(url, data);

        dispatch({
            type: SEND_CHAT_MESSAGE_REQUEST
        });

        request.then(() => {
            dispatch({
                type: SEND_CHAT_MESSAGE_SUCCESS
            });
        }, (error) => {
            dispatch({
                type: SEND_CHAT_MESSAGE_FAILED,
                payload: getError(error)
            });
        });
    }
}
