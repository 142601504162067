export const MODULE_LIST = [ 
    'Analyze', 
    'Gather', 
    'Plan', 
    'Manage', 
    'Pay', 
    'Communicate', 
    'Validate', 
    'Share' 
];

export const VOTER_UPLOAD_HEADERS = [
    'name',
    'address',
    'sex',
    'birthdate',
    'province',
    'district',
    'municity',
    'barangay',
    'precinct'
];

export const USER_UPLOAD_HEADERS = [
    'name',
    'address',
    'sex',
    'birthdate',
    'provinces',
    'districts',
    'municities',
    'barangays',
    'precincts',
    'email',
    'contact_no'
];

export const UNIT_LEVELS = [
    'province',
    'municity',
    'barangay',
    'precinct'
];

export const RESOURCE_TYPES = [
    'USER',
    'VOTER'
];

export const VARIABLE_DATA_TYPES = [
    'STRING',
    'ARRAY'
];

export const VARIABLE_KPI_TYPES = [
    'PREFERENCE',
    'ATTENDANCE',
    'SOCIAL'
];

export const SEXES = [
    'Male',
    'Female'
];

export const INTERVALS = [
    'daily',
    'weekly',
    'monthly',
    'yearly'
]