import { useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row, Stack, Table } from "react-bootstrap"
import { useDispatch } from "react-redux";
import { clearUserErrorsAction, updateUserAction, addUserAction, getUser } from "actions/userActions.js";
import { useSelector } from "react-redux";
import { formatDate } from "helpers";
import { getRoleListAction } from "actions/roleActions";
import { roleSelector } from "selectors/roleSelector";
import { precinctSelector } from "selectors/precinctSelector";
import { barangaySelector } from "selectors/barangaySelector";
import { municipalitySelector } from "selectors/municipalitySelector";
import { provinceSelector } from "selectors/provinceSelector";
import { getPrecinctListAction } from "actions/precinctActions";
import { getBarangayListAction } from "actions/barangayActions";
import { getMunicipalityListAction } from "actions/municipalityActions";
import { getProvinceListAction } from "actions/provinceActions";
import ReactSelect from "react-select";
import { getDistrictListAction } from "actions/districtActions";
import { districtSelector } from "selectors/districtSelector";
import { userSelector } from "selectors/userSelector";

function UserModal(props) {
    const { roles } = useSelector(roleSelector);
    const { precincts } = useSelector(precinctSelector);
    const { barangays } = useSelector(barangaySelector);
    const { municipalities } = useSelector(municipalitySelector);
    const { districts } = useSelector(districtSelector);
    const { provinces } = useSelector(provinceSelector);
    const { onHide } = props;
    const dispatch = useDispatch();
    const { user, userIsLoading } = useSelector(userSelector);
    const [formData, setFormData] = useState(props.user);

    const handleClose = () => {
        dispatch(clearUserErrorsAction());
        onHide();
    }

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    };

    const handleSubmit = () => {
        if (user?.id) {
            dispatch(updateUserAction(formData.id, formData));
        } else {
            dispatch(addUserAction(formData));
        }

        handleClose();
    };


    useEffect(() => {
        dispatch(getRoleListAction());
        dispatch(getPrecinctListAction());
        dispatch(getBarangayListAction());
        dispatch(getMunicipalityListAction());
        dispatch(getDistrictListAction());
        dispatch(getProvinceListAction());
        dispatch(getUser(props.user.id))
    }, [dispatch, props.user.id]);

    return (
        <Modal onHide={handleClose} show={true} size="lg">
            <Modal.Header closeButton>User Profile</Modal.Header>
            <Modal.Body>
                <Form>
                    <Stack gap={2}>
                        <h4>Personal Information</h4>
                        <Row>
                            <Col lg={3}><Form.Label>Name</Form.Label></Col>
                            <Col lg={9}><Form.Control defaultValue={formData.name} name="name" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Sex</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="sex" defaultValue={formData.sex} onChange={handleChange}>
                                    <option value="">Select</option>
                                    <option value="M">Male</option>
                                    <option value="F">Female</option>
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Birthdate</Form.Label></Col>
                            <Col lg={9}><Form.Control type="date" defaultValue={formatDate(formData.birthdate)} name="birthdate" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Contact No</Form.Label></Col>
                            <Col lg={9}><Form.Control defaultValue={formData.contact_no} name="contact_no" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Email</Form.Label></Col>
                            <Col lg={9}><Form.Control defaultValue={formData.email} name="email" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Username</Form.Label></Col>
                            <Col lg={9}><Form.Control defaultValue={formData.username} name="username" onChange={handleChange} /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Role</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="role_id" defaultValue={formData.role_id} onChange={handleChange}>
                                    {
                                        roles.map((role) => (
                                            <option key={role.id} value={role.id}>{role.name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Col>
                        </Row>
                        <h4>Assignments</h4>        
                        <Row>
                            <Col lg={3}><Form.Label>Provinces</Form.Label></Col>
                            <Col lg={9}>
                                <ReactSelect
                                    closeMenuOnSelect={false}
                                    defaultValue={formData.provinces.map((province) => ({ value: province, label: province }))}
                                    isMulti
                                    options={provinces.map((province) => ({ value: province, label: province }))}
                                    onChange={
                                        (selectedOptions) => handleChange(
                                            { 
                                                target: {
                                                    name: 'provinces', 
                                                    value: selectedOptions.map((selectedOption) => (selectedOption.value))
                                                } 
                                            }
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Districts</Form.Label></Col>
                            <Col lg={9}>
                                <ReactSelect
                                    closeMenuOnSelect={false}
                                    defaultValue={formData.districts.map((district) => ({ value: district, label: district }))}
                                    isMulti
                                    options={districts.map((district) => ({ value: district, label: district }))}
                                    onChange={
                                        (selectedOptions) => handleChange(
                                            { 
                                                target: {
                                                    name: 'districts', 
                                                    value: selectedOptions.map((selectedOption) => (selectedOption.value))
                                                } 
                                            }
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Municipality/City</Form.Label></Col>
                            <Col lg={9}>
                                <ReactSelect
                                    closeMenuOnSelect={false}
                                    defaultValue={formData.municities.map((municity) => ({ value: municity, label: municity }))}
                                    isMulti
                                    options={municipalities.map((municity) => ({ value: municity, label: municity }))}
                                    onChange={
                                        (selectedOptions) => handleChange(
                                            { 
                                                target: {
                                                    name: 'municities', 
                                                    value: selectedOptions.map((selectedOption) => (selectedOption.value))
                                                } 
                                            }
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Barangay</Form.Label></Col>
                            <Col lg={9}>
                                <ReactSelect
                                    closeMenuOnSelect={false}
                                    defaultValue={formData.barangays.map((barangay) => ({ value: barangay, label: barangay }))}
                                    isMulti
                                    options={barangays.map((barangay) => ({ value: barangay, label: barangay }))}
                                    onChange={
                                        (selectedOptions) => handleChange(
                                            { 
                                                target: {
                                                    name: 'barangays', 
                                                    value: selectedOptions.map((selectedOption) => (selectedOption.value))
                                                } 
                                            }
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Precincts</Form.Label></Col>
                            <Col lg={9}>
                                <ReactSelect
                                    closeMenuOnSelect={false}
                                    defaultValue={formData.precincts.map((precinct) => ({ value: precinct, label: precinct }))}
                                    isMulti
                                    options={precincts.map((precinct) => ({ value: precinct, label: precinct }))}
                                    onChange={
                                        (selectedOptions) => handleChange(
                                            { 
                                                target: {
                                                    name: 'precincts', 
                                                    value: selectedOptions.map((selectedOption) => (selectedOption.value))
                                                } 
                                            }
                                        )
                                    }
                                />
                            </Col>
                        </Row>
                        <h4>Claimed Voters</h4>
                        {
                            userIsLoading ? <p>Please wait...</p> : <Table>
                            <thead>
                                <th>Name</th>
                                <th>Last interviewed on</th>
                            </thead>
                            <tbody>
                                {
                                    user?.claimed_voter_names?.map((voter) => (
                                        <tr>
                                            <td>{voter.name}</td>
                                            <td>{voter.latest_interview ? formatDate(voter.latest_interview.submitted_at) : '-'}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>
                        }                        
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger">Reset Password</Button>{" "}
                <Button variant="success" onClick={() => handleSubmit()}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UserModal