import "./VariableModal.css";
import { Button, Col, Form, Modal, Row, Stack, Table } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPowerOff } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVariableAction, updateVariableAction, getVariableListAction, getVariableAction } from "actions/variableActions";
import { addVariableOptionAction, updateVariableOptionAction } from "actions/variableOptionActions";
import { v4 as uuidv4 } from "uuid";
import { RESOURCE_TYPES, VARIABLE_DATA_TYPES, VARIABLE_KPI_TYPES } from "constants/common";
import { getProvinceListAction } from "actions/provinceActions";
import { getMunicipalityListAction } from "actions/municipalityActions";
import { provinceSelector } from "selectors/provinceSelector";
import { municipalitySelector } from "selectors/municipalitySelector";
import { variableSelector } from "selectors/variableSelector";
import ReactSelect from "react-select";
import { districtSelector } from "selectors/districtSelector";
import { barangaySelector } from "selectors/barangaySelector";
import { precinctSelector } from "selectors/precinctSelector";

function VariableModal(props) {
    const { onHide, variable_id, show } = props;
    const dispatch = useDispatch();
    const { provinces } = useSelector(provinceSelector);
    const { districts } = useSelector(districtSelector);    
    const { municipalities } = useSelector(municipalitySelector);
    const { barangays } = useSelector(barangaySelector);
    const { precincts } = useSelector(precinctSelector);

    const { variable } = useSelector(variableSelector);
    const [ formData, setFormData ] = useState({
        id: '',
        name: '',       
        variable_type: 'string',
        is_active: true,
        resource_type: 'voter',
        label: ''
    });
    const [ options, setOptions ] = useState();

    const handleAddOption = () => {
        setOptions((prev) => [...prev, { variable_id: variable.id, name: "", is_active: true, provinces: [], districts: [], municities: [], barangays: [], precincts: [] }]);
    }

    const handleToggleOption = (variableOptionId) => {
        setOptions((prev) => {
            return prev.map(option => 
                option.id === variableOptionId 
                    ? { ...option, is_active: !option.is_active }
                    : option
            );
        });
    }

    const handleOptionChange = (e, index) => {
        setOptions((prev) => prev.map((option, i) => 
            i === index ? { ...option, [e.target.name]: e.target.value } : option
        ));
    };

    const handleOptionSave = () => {
        options.forEach((option) => {
            if (option.id) {
                dispatch(updateVariableOptionAction(option.id, option));
            } else {
                dispatch(addVariableOptionAction(option));
            }
        })
    };

    const handleChange = (e) => {
        console.log(e);
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    };

    const handleSubmit = async() => {
        if (variable?.id) {
            dispatch(updateVariableAction(formData.id, formData));
            handleOptionSave();
        } else {
            dispatch(addVariableAction(formData));
        }
        dispatch(getVariableListAction({is_active: true}));
        onHide();
    };

    useEffect(() => {
        dispatch(getVariableListAction({is_active: true}));
        dispatch(getProvinceListAction());
        dispatch(getMunicipalityListAction());
    }, [dispatch]);

    useEffect(() => {
        dispatch(getVariableAction(variable_id));
    }, [dispatch, variable_id]);

    useEffect(() => {
        if (variable) {
            setFormData(
                {
                    id: variable.id,
                    name: variable.name,       
                    variable_type: variable.variable_type || 'string',
                    is_active: variable.is_active || true,
                    resource_type: variable.resource_type || 'voter',
                    label: variable.label || '',
                    kpi_type: variable.kpi_type
                }
            );
            setOptions(variable.options);
        }
    }, [variable]);

    return(
        <Modal id="variable-modal" onHide={onHide} show={show} size="fullscreen" animation>
            <Modal.Header closeButton>Variable Profile</Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => e.preventDefault()}>
                    <Stack gap={2}>
                        <Row>
                            <Col lg={3}><Form.Label>Name</Form.Label></Col>
                            <Col lg={9}><Form.Control name="name" onChange={handleChange} defaultValue={formData.name} placeholder="Enter a name for this variable." /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Data Type</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="variable_type" onChange={handleChange} value={formData.variable_type}>
                                    {VARIABLE_DATA_TYPES.map((type) => (
                                        <option key={uuidv4()} value={type}>{type}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>KPI Type</Form.Label></Col>
                            <Col lg={9}>
                                <Form.Select name="kpi_type" onChange={handleChange} value={formData.kpi_type}>
                                    <option value="">Not KPI</option>
                                    {VARIABLE_KPI_TYPES.map((type) => (
                                        <option key={uuidv4()} value={type}>{type}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Label</Form.Label></Col>
                            <Col lg={9}><Form.Control name="label" onChange={handleChange} defaultValue={formData.label} placeholder="Enter a question for this variable." /></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Resource Type</Form.Label></Col>
                            <Col lg={9}><Form.Select name="resource_type" onChange={handleChange} value={formData.resource_type}>
                                {RESOURCE_TYPES.map((resourceType) => (
                                    <option key={uuidv4()} value={resourceType}>{resourceType}</option>
                                ))}
                            </Form.Select></Col>
                        </Row>
                        <Row>
                            <Col lg={3}><Form.Label>Enabled</Form.Label></Col>
                            <Col lg={9}><Form.Check name="is_active" onClick={(e) => handleChange({ target: { value: e.target.checked, name: 'is_active' } })} defaultChecked={formData.is_active} /></Col>
                        </Row>                        
                        <div className="variable-options">
                            {formData.variable_type !== 'STRING' && options?.length > 0 && <Table>                                
                                <thead>
                                    <tr>
                                        <th width="2%">No.</th>
                                        <th width="40%">Option</th>
                                        <th width="10%">Provinces</th>
                                        <th width="10%">Districts</th>
                                        <th width="10%">Municities</th>
                                        <th width="10%">Barangays</th>
                                        <th width="10%">Precincts</th>
                                        <th width="10%">Action</th>
                                    </tr>
                                </thead>
                                <tbody>                                
                                    {options.map((option, index) => (
                                        <tr key={uuidv4()}>
                                            <td>{index + 1}</td>
                                            <td><Form.Control name="name" disabled={!option.is_active} defaultValue={option.name} onBlur={(e) => handleOptionChange(e, index)} /></td>
                                            <td>
                                                <ReactSelect
                                                    closeMenuOnSelect={false}
                                                    defaultValue={option.provinces.map((province) => ({ value: province, label: province }))}
                                                    isMulti
                                                    options={provinces.map((province) => ({ value: province, label: province }))}
                                                    onChange={
                                                        (selectedOptions) => handleOptionChange(
                                                            { 
                                                                target: {
                                                                    name: 'provinces', 
                                                                    value: selectedOptions.map((selectedOption) => (selectedOption.value))
                                                                } 
                                                            }, index
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <ReactSelect
                                                    closeMenuOnSelect={false}
                                                    defaultValue={option.districts.map((district) => ({ value: district, label: district }))}
                                                    isMulti
                                                    options={districts.map((district) => ({ value: district, label: district }))}
                                                    onChange={
                                                        (selectedOptions) => handleOptionChange(
                                                            { 
                                                                target: {
                                                                    name: 'districts', 
                                                                    value: selectedOptions.map((selectedOption) => (selectedOption.value))
                                                                } 
                                                            }, index
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <ReactSelect
                                                    closeMenuOnSelect={false}
                                                    defaultValue={option.municities.map((municity) => ({ value: municity, label: municity }))}
                                                    isMulti
                                                    options={municipalities.map((municity) => ({ value: municity, label: municity }))}
                                                    onChange={
                                                        (selectedOptions) => handleOptionChange(
                                                            { 
                                                                target: {
                                                                    name: 'municities', 
                                                                    value: selectedOptions.map((selectedOption) => (selectedOption.value))
                                                                } 
                                                            }, index
                                                        )
                                                    }
                                                />
                                            </td>
                                            <td>
                                                <ReactSelect
                                                    closeMenuOnSelect={false}
                                                    defaultValue={option.barangays.map((barangay) => ({ value: barangay, label: barangay }))}
                                                    isMulti
                                                    options={barangays.map((barangay) => ({ value: barangay, label: barangay }))}
                                                    onChange={
                                                        (selectedOptions) => handleOptionChange(
                                                            { 
                                                                target: {
                                                                    name: 'barangays', 
                                                                    value: selectedOptions.map((selectedOption) => (selectedOption.value))
                                                                } 
                                                            }, index
                                                        )
                                                    }
                                                />
                                            </td>        
                                            <td>
                                                <ReactSelect
                                                    closeMenuOnSelect={false}
                                                    defaultValue={option.precincts.map((precinct) => ({ value: precinct, label: precinct }))}
                                                    isMulti
                                                    options={precincts.map((precinct) => ({ value: precinct, label: precinct }))}
                                                    onChange={
                                                        (selectedOptions) => handleOptionChange(
                                                            { 
                                                                target: {
                                                                    name: 'precincts', 
                                                                    value: selectedOptions.map((selectedOption) => (selectedOption.value))
                                                                } 
                                                            }, index
                                                        )
                                                    }
                                                />
                                            </td>                                     
                                            <td>
                                                <Button
                                                    variant={option.is_active ? "danger" : "outline-danger"}
                                                    size="sm"
                                                    onClick={() => handleToggleOption(option.id)}
                                                >
                                                    <FontAwesomeIcon icon={faPowerOff} />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>}
                        </div>
                        {!variable?.id && <p>To add options, please create the variable first.</p>}
                        {variable?.id && (variable?.variable_type === "Preference" && variable?.options.length === 0) && <p>To add options, please click on the "Add Option" button below.</p>}
                    </Stack>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" disabled={!variable?.id || formData.variable_type === 'STRING'} onClick={handleAddOption}>Add Option</Button>
                <Button variant="success" onClick={() => handleSubmit()}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default VariableModal;