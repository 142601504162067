import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button, Stack } from 'react-bootstrap';
import { formatDate } from 'helpers';

const renderCriteria = (criteria) => {
  return criteria.map(({ criteria_id, label, lower_limit, upper_limit }) => (
    <div key={criteria_id} className='small'>
      {label}: {lower_limit} - {upper_limit}%
    </div>
  ));
}

const TableRow = (kpi, index, handleDeleteClick) => (
  <tr key={kpi.id}>
    <td>{index + 1}</td>
    <td>{kpi.name}</td>
    <td>
      <span>{kpi.province} - {kpi.district} - {kpi.municity} - {kpi.barangay} - {kpi.precinct}</span>
    </td>
    <td>{formatDate(kpi.start_date)}</td>
    <td>{formatDate(kpi.end_date)}</td>
    <td>{kpi.target_value}</td>
    <td>{kpi.percentage_value}</td>
    <td>{renderCriteria(kpi.criteria)}</td>
    <td>
      <Stack direction='horizontal' gap={1} className='justify-content-center'>
        <Link
          to={`${kpi.id}`}
          className='btn btn-sm btn-success'
          aria-label="View KPI details"
        >
          <FontAwesomeIcon icon={faEye} />
        </Link>
        <Button
          variant='danger'
          size='sm'
          onClick={() => handleDeleteClick(kpi)}
          aria-label="Delete KPI"
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Stack>
    </td>
  </tr>
);

export default TableRow;