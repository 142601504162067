import getURL from "api/config";
import { POST, PATCH, GET, DELETE } from "api/index";
import {
    GET_USERS_REQUEST,
    GET_USER_REQUEST,
    USER_LIST_LOAD_REQUEST,
    USER_LIST_LOAD_SUCCESS,
    USER_LIST_LOAD_FAILED,
    ADD_USER_SUCCESS,
    ADD_USER_FAILED,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILED,
    CLEAR_USER_ERRORS,
    UPLOAD_USERS_REQUEST,
    UPLOAD_USERS_SUCCESS,
    UPLOAD_USERS_FAILED,
    GET_USER_SUCCESS,
    GET_USER_FAILED,
    DELETE_USER_FAILED,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS
} from "constants/actionTypes";
import { getError } from "helpers";
import { MULTIPART_HEADERS } from "constants/http";

export function getUserListAction(payload) {
    return function action(dispatch) {
        const url = getURL("GET_USER_LIST");
        const request = POST(url, payload);
    
        dispatch({
            type: USER_LIST_LOAD_REQUEST,
            payload: { users: [], isLoading: true, total: 0 }
        })

        request.then((response) => {
            const users = response?.data?.result.data || [];
            const total = response?.data?.result.total || 0;
            const lastPage = response?.data?.result.last_page || 0;
            const currentPage = response?.data?.result.current_page || 0;
            dispatch({
                isLoading: false,
                type: USER_LIST_LOAD_SUCCESS,
                payload: { users, total, lastPage, currentPage }
            })
        }, (error) => {
            dispatch({
                isLoading: false,
                type: USER_LIST_LOAD_FAILED,
                payload: {
                    errors: getError(error)
                }
            });
        });   
    };
};

export function addUserAction(payload) {
    return function action(dispatch) {
        const url = getURL("INSERT_USER");
        const request = POST(url, payload);

        request.then((response) => {
            const user = response?.data?.data || {};
            dispatch({
                type: ADD_USER_SUCCESS,
                payload: { user }
            })
        }, (error) => {
            dispatch({
                type: ADD_USER_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        })
    }
};

export const updateUserAction = (id, payload) => async(dispatch) => {
    try {
        const url = getURL("UPDATE_USER")(id);
        const response = await PATCH(url, payload);
        const user = response?.data?.data || {};

        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: { user }
        })
    } catch(error) {
        dispatch({
            type: UPDATE_USER_FAILED,
            payload: {
                errors: getError(error)
            }
        })
    }
};

export const  updateUserVoterIdAction = (id, payload) => async(dispatch) => {
    try {
        const url = getURL("UPDATE_USER_VOTER_ID")(id);
        const response = await PATCH(url, payload);
        const user = response?.data?.result || {};

        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: { user }
        })
    } catch(error) {
        dispatch({
            type: UPDATE_USER_FAILED,
            payload: {
                errors: getError(error)
            }
        })
    }
}

export function clearUserErrorsAction() {
    return function action(dispatch) {
        dispatch({
            type: CLEAR_USER_ERRORS
        })
    };
};

export function uploadUsersAction(data) {
    return function action(dispatch) {
        const url = getURL("UPLOAD_USERS");
        const request = POST(url, data, MULTIPART_HEADERS);

        dispatch({
            type: UPLOAD_USERS_REQUEST,
            payload: { isLoading: true, uploadError: false }
        })

        request.then((response) => {
            dispatch({
                type: UPLOAD_USERS_SUCCESS,
                payload: { users: response.data.result, isLoading: false, uploadError: false }
            })
        }, (error) => {
            dispatch({
                type: UPLOAD_USERS_FAILED,
                payload: { errors: getError(error), isLoading: false, uploadError: true }
            })
        });
    };
};

export function getUserAction(id) {
    return function action(dispatch) {
        const url = getURL("GET_USER")(id);
        const request = GET(url);

        request.then((response) => {
            const user = response?.data?.result || {};
            dispatch({
                type: GET_USER_SUCCESS,
                payload: { user }
            })
        }, (error) => {
            dispatch({
                type: GET_USER_FAILED,
                payload: { errors: getError(error) }
            })
        })
    }
}   


export function getUsers(queryParams) {
    return function action(dispatch) {
        const url = getURL("GET_USERS");
        const request = GET(url, {}, {}, queryParams);

        dispatch({
            type: GET_USERS_REQUEST,
            payload: { 
                isLoading: true,
                usersError: null 
            }
        });

        request.then((response) => {
            const total = response?.data?.data?.total;
            const users = response?.data?.data?.data;
            const usersCurrentPage = response?.data?.data?.current_page;
            const usersLastPage = response?.data?.data?.last_page;
            dispatch({
                type: GET_USERS_REQUEST,
                payload: { 
                    isLoading: false,
                    total,
                    users, 
                    usersCurrentPage,
                    usersLastPage,
                    usersError: null
                }
            });
        }, (error) => {
            dispatch({
                type: GET_USERS_REQUEST,
                payload: { 
                    isLoading: false,
                    users: [],
                    usersError: getError(error)
                }
            });
        });
    };
};

export function getUser(userId) {
    return function action(dispatch) {
        const url = getURL("GET_USER")(userId);
        const request = GET(url);

        dispatch({
            type: GET_USER_REQUEST,
            payload: { userIsLoading: true, user: null, userError: null }
        });

        request.then((response) => {            
            const user = response?.data?.data;
            dispatch({
                type: GET_USER_SUCCESS,
                payload: { user, userIsLoading: false, userError: null }
            })
        }, (error) => {
            dispatch({
                type: GET_USER_FAILED,
                payload: { userIsLoading: false, user: null, userError: getError(error) }
            })
        });
    };
};

export function removeUser(userId) {
    return function action(dispatch) {
        const url = getURL("DELETE_USER")(userId);
        const request = DELETE(url);

        dispatch({
            type: DELETE_USER_REQUEST,
            payload: { isLoading: true, user: null, userError: null }
        });

        request.then((response) => {
            const user = response?.data?.result;
            dispatch({
                type: DELETE_USER_SUCCESS,
                payload: { user, isLoading: false, userError: null }
            })
        }, (error) => {
            dispatch({
                type: DELETE_USER_FAILED,
                payload: { isLoading: false, user: null, userError: getError(error) }
            })
        });
    };    
};