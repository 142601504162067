import { Button, Card, Form, FormControl, InputGroup, ListGroup } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { chatSelector } from 'selectors/chatSelector';
import { getChatMessages, getChats, sendMessage } from 'actions/chatActions';
import { sessionSelector } from 'selectors/sessionSelector';

function ActiveChat(params) {
    const { pusher } = params;
    const { activeChat, messages } = useSelector(chatSelector);
    const dispatch = useDispatch();
    const [chatMessages, setChatMessages] = useState([]);
    const { sessionUser } = useSelector(sessionSelector);
    const [newMessage, setNewMessage] = useState('');
    const channel = pusher.subscribe(activeChat?.id || 'none');
    const latestMessageRef = useRef();

    const getChatGroupName = (chat) => {
        if (!chat) {
            return '';
        }

        if (chat.name !== null) {
            return chat.name;
        }

        let name = chat.members[0]['name'];

        if (chat.members.length > 1) {
            name = chat.members.map(member => member['name']).join(', ');
        }

        return name;
    };

    const handleMessageChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleMessageSend = async (e) => {
        e.preventDefault();
        if (newMessage) {
            dispatch(sendMessage(activeChat.id, { content: newMessage })); 
            setNewMessage('');
        }        
    }

    useEffect(() => {
        if (activeChat) {
            dispatch(getChatMessages(activeChat.id));            
            channel.bind('message', function(data) {
                setChatMessages((prev) => [...prev, data.message]);
                dispatch(getChats());
            });
        }
        // eslint-disable-next-line
    }, [activeChat]);

    useEffect(() => {
        setChatMessages(messages);
    }, [messages]);

    useEffect(() => {
        if (latestMessageRef.current) {
            latestMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatMessages]);

    
    return(
        <section id='communicate-active-chat'>
            {activeChat ? <Card>
                <Card.Header>{getChatGroupName(activeChat)}</Card.Header>
                <Card.Body>
                    <ListGroup>
                        {
                            chatMessages.map((message, index) => (
                                <ListGroup.Item key={`${message.id}-message`} ref={(index === chatMessages.length-1) ? latestMessageRef : null} className={message.sender.id === sessionUser.id ? 'text-end' : '' }>
                                    <strong>{message.sender.name}</strong>
                                    <p>{message.content}</p>
                                </ListGroup.Item>
                            ))
                        }
                    </ListGroup>
                    {
                        chatMessages.length === 0 && <p className='text-center'>Start a conversation. Say hi!</p>
                    }
                </Card.Body>
                <Card.Footer>
                    <Form onSubmit={handleMessageSend}>
                        <InputGroup>
                            <FormControl name='message' placeholder="Say something" onChange={handleMessageChange} value={newMessage} />
                            <Button type='submit'>Send</Button>
                        </InputGroup>
                    </Form>                    
                </Card.Footer>                
            </Card> : ''}
        </section>
    )
}

export default ActiveChat;