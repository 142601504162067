import { Button, Modal, Form, Row, Col, Stack, Spinner } from "react-bootstrap";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_UPLOAD_HEADERS } from "constants/common";
import { uploadUsersAction, getUserListAction } from "actions/userActions";
import { getVariableListAction } from "actions/variableActions";
import { variableSelector } from "selectors/variableSelector";
import { UPLOAD_USERS_REQUEST } from "constants/actionTypes";
import { parse, unparse } from "papaparse";

function UserUploadMapper({show, onHide}) {
    const inputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [mappedHeaders, setMappedHeaders] = useState([]);
    const [sheetHeaders, setSheetHeaders] = useState([]);
    const [data, setData] = useState([]);
    const { variables } = useSelector(variableSelector);

    const dispatch = useDispatch();

    const getExistingHeaders = () => {
        return USER_UPLOAD_HEADERS.concat(variables.map((variable) => variable.name.toLowerCase()));
    }

    const handleFileUpload = async () => {
        setIsLoading(true);
        const file = inputRef.current.files[0];
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = () => {                 
            parse(file, {
                complete: function(results) {
                    const parsedData = results.data;
                    const headers = Object.keys(parsedData[0]).map(function(header) {
                        return header.toLowerCase();
                    });
                    const rows = parsedData.map(function(row) {
                        return Object.values(row);
                    });
                    setData(rows);
                    setSheetHeaders(headers);
                    setMappedHeaders(headers);
                },
                header: true
            });
        };        
    };
    
    const handleClose = () => {
        setData([]);
        setSheetHeaders([]);
        setMappedHeaders(getExistingHeaders());
        dispatch({
            type: UPLOAD_USERS_REQUEST,
            payload: { isLoading: false, uploadError: false }
        })
        onHide();
    };

    const handleColumnMapping = (e, index) => {
        const newMappedHeaders = [...mappedHeaders];
        newMappedHeaders[index] = e.target.value;
        setMappedHeaders(newMappedHeaders);
    };

    const handleImport = async () => {
        setIsLoading(true);
        const rows = data; 
        rows.unshift(mappedHeaders);
        const newCsv = unparse(rows);
        const newFile = new Blob([newCsv], { type: 'text/csv' });
        const formData = new FormData();
        formData.append('file', newFile);
        dispatch(uploadUsersAction(formData));
        setIsLoading(false);
        dispatch(getUserListAction());
        handleClose();
    };

    useEffect(() => {
        dispatch(getVariableListAction({is_active: true}));
    }, [dispatch]);

    useEffect(() => {
        if (data.length > 0) {
            setIsLoading(false);
        }
    }, [data]);

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Importing Users</Modal.Title>                    
            </Modal.Header>
            <Modal.Body>
                <p>
                    <small className="text-muted">To import your user data, we will need you to map out the columns of your file to the existing fields.</small>                
                    <br />
                    <small className="text-muted">Please select the column that corresponds to the field.</small>                
                </p>                
                <Form className="mt-3">
                    {sheetHeaders.length === 0 && <Form.Group>
                        <Form.Label>
                            <small>Click on the "Browse" button to select your user data file.</small>
                        </Form.Label>
                        <Form.Control ref={inputRef} type="file" onChange={handleFileUpload} />
                    </Form.Group>}
                    <Stack gap={2}>
                        {
                            sheetHeaders.map((header, index) => (
                                <Form.Group key={index}>
                                <Row>
                                    <Col lg={6}>
                                        <Form.Label>
                                            <small>{header}</small>
                                        </Form.Label>
                                    </Col>
                                    <Col lg={6}>
                                        <Form.Select value={mappedHeaders[index]?.toLowerCase() || ""} onChange={(e) => handleColumnMapping(e, index)}>
                                            <option value="">New Column</option>
                                            {
                                                getExistingHeaders().map((header, index) => (
                                                    <option key={index} value={header}>{header}</option>
                                                ))
                                            }
                                        </Form.Select>
                                    </Col>
                                </Row>
                            </Form.Group>
                            ))
                        }                        
                    </Stack>
                </Form>  
                {isLoading && <div className="text-center mt-3">
                    <Spinner animation="border" role="status" />
                </div>}              
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleImport}>
                    {isLoading ? <Spinner animation="border" role="status" /> : 'Import'}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default UserUploadMapper;