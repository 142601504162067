import { useEffect, useState } from 'react';
import { variableSelector } from 'selectors/variableSelector';
import { useGeopoliticalUnits } from 'hooks/useGeopoliticalUnits';
import { useGeopoliticalPopulation } from 'hooks/useGeopoliticalPopulation';
import { formatDate } from 'helpers';
import { Button, Col, Row } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import CriteriaSection from './CriteriaSection';
import KpiGraph from '../KpiGraph';
import LoadingOverlay from 'components/common/LoadingOverlay';
import { useVariables } from 'hooks/useVariables';
import { useVariableOptions } from 'hooks/useVariableOptions';

const KpiForm = ({ kpi, isLoading, onSubmit, mode = 'create' }) => {
	const { variables } = useVariables(variableSelector);
	const [title, setTitle] = useState('');
	// Initialize states with existing data if in edit mode
	const [formData, setFormData] = useState({
		province: kpi?.province || '',
		district: kpi?.district || '',
		municity: kpi?.municity || '',
		barangay: kpi?.barangay || '',
		precinct: kpi?.precinct || '',
		variable_id: kpi?.variable_id || '',
		variable_option_id: kpi?.variable_option_id || '',
		kpi_name: kpi?.name || '',
		start_date: formatDate(kpi?.start_date) || '',
		end_date: formatDate(kpi?.end_date) || '',
		target_value: kpi?.target_value || '',
		percentage_value: kpi?.percentage_value || '',
	});
	const {
		provinces,
		districts,
		muniCities,
		barangays,
		precincts
	} = useGeopoliticalUnits({ ...formData, muniCity: formData.municity });
	const { population } = useGeopoliticalPopulation({
		province: formData.province,
		district: formData.district,
		municity: formData.municity,
		barangay: formData.barangay,
		precinct: formData.precinct
	});
	const { options } = useVariableOptions(formData.variable_id);
	const [criteria, setCriteria] = useState(
		kpi?.criteria || [
			{ label: '', lower_limit: '', upper_limit: '' },
			{ label: '', lower_limit: '', upper_limit: '' }
		]
	);
	const [isSaving, setIsSaving] = useState('false');

	const handleFormChange = (e) => {
		const { name, value } = e.target

		// Clear dependent fields based on hierarchy
		const updates = { [name]: value };

		// Handle target and percentage value calculation
		if (name === 'target_value' && value && population) {
			// Ensure target value doesn't exceed population
			const validatedValue = Math.min(Number(value), population);
			const percentageValue = ((validatedValue / population) * 100).toFixed(3);
			updates.target_value = validatedValue;
			updates.percentage_value = percentageValue;
		} else if (name === 'percentage_value' && value && population) {
			// Ensure percentage value doesn't exceed 100
			const validatedPercentage = Math.min(Number(value), 100);
			const targetValue = Math.round((validatedPercentage / 100) * population);
			updates.percentage_value = validatedPercentage;
			updates.target_value = targetValue;
		}

		switch (name) {
			case 'province':
				updates.district = '';
				updates.municity = '';
				updates.barangay = '';
				updates.precinct = '';
				break;
			case 'district':
				updates.municity = '';
				updates.barangay = '';
				updates.precinct = '';
				break;
			case 'municity':
				updates.barangay = '';
				updates.precinct = '';
				break;
			case 'barangay':
				updates.precinct = '';
				break;
			default:
				break;
		}

		setFormData((prev) => ({
			...prev,
			...updates
		}))
	}

	const handleSubmit = async (e) => {
		e.preventDefault();

		const payload = {
			...formData,
			is_save: isSaving,
			criteria: criteria
		}

		try {
			await onSubmit(payload);
		} catch (error) {
			console.error('Failed to save KPI:', error);
		}
	}

	const handleCriteriaChange = (index, field, value) => {
		const newCriteria = [...criteria];
		newCriteria[index] = { ...newCriteria[index], [field]: value };
		setCriteria(newCriteria);
	}

	const addCriteria = () => {
		if (criteria.length < 5) {
			setCriteria([...criteria, { label: '', lower_limit: '', upper_limit: '' }]);
		}
	}

	const removeCriteria = (index) => {
		if (criteria.length > 2) {
			setCriteria(criteria.filter((_, i) => i !== index));
		}
	}

	const isFormValid = () => {
		const hasLocation = formData.province || formData.district ||
			formData.municity || formData.barangay ||
			formData.precinct;

		const hasRequiredFields = formData.variable_id && formData.variable_option_id &&
			formData.kpi_name &&
			formData.start_date &&
			formData.end_date &&
			formData.target_value &&
			formData.percentage_value;

		const hasCriteria = criteria.every(c =>
			c.label && c.lower_limit !== '' && c.upper_limit !== ''
		);

		return hasLocation && hasRequiredFields && hasCriteria;
	};

	useEffect(() => {
		if (population && formData.percentage_value) {
			const newTargetValue = Math.round((Number(formData.percentage_value) / 100) * population);
			setFormData((prev) => ({ ...prev, target_value: newTargetValue }));
		}
	}, [formData.percentage_value, population])

	useEffect(() => {
		if (formData.variable_id && formData.variable_option_id) {
			var title = `${formData.kpi_name} - ${options?.find(option => option.id === formData.variable_option_id).name}`
			setTitle(title)
		}
	}, [options, formData.kpi_name, formData.variable_id, formData.variable_option_id])

	return (
		<>
			<LoadingOverlay
				show={isLoading}
				message={'Loading...'}
			/>
			<Form onSubmit={handleSubmit} id='kpiForm'>
				<KpiFormDropdown
					formData={formData}
					handleFormChange={handleFormChange}
					provinces={provinces}
					districts={districts}
					muniCities={muniCities}
					barangays={barangays}
					precincts={precincts}
					variables={variables}
					options={options}
				/>

				<Row className='mb-3'>
					<Col>
						<h5>Population: {population?.toLocaleString()}</h5>
					</Col>
				</Row>

				<Row>
					<KpiFormFields
						formData={formData}
						handleFormChange={handleFormChange}
						population={population}
						criteria={criteria}
						setCriteria={setCriteria}
						addCriteria={addCriteria}
						removeCriteria={removeCriteria}
						handleCriteriaChange={handleCriteriaChange}
						mode={mode}
					/>
				</Row>

				<div className="d-flex">
					<Button
						type='submit'
						onClick={() => setIsSaving('false')}
						disabled={!isFormValid()}
					>
						Generate Graph
					</Button>
					<Button
						variant='success'
						type='submit'
						className='mx-2'
						onClick={() => setIsSaving('true')}
						disabled={!isFormValid()}
					>
						Save
					</Button>
				</div>
				<hr />

				<Row>
					<Col xs={12}>
						<KpiGraph data={kpi?.bar_data || []} population={population || kpi?.target_value} mode={mode} title={title} />
					</Col>
				</Row>
			</Form>
		</>
	)
}

const KpiFormDropdown = ({ formData, handleFormChange, provinces, districts, muniCities, barangays, precincts, variables, options }) => {
	return (
		<>
			<Row className='mb-2'>
				<Col>
					<Form.Group>
						<Form.Label>Province</Form.Label>
						<Form.Select
							name='province'
							onChange={handleFormChange}
							value={formData.province}
						>
							<option value=''>Select one</option>
							{provinces.map((option) => (
								<option key={option} value={option}>{option}</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group>
						<Form.Label>District</Form.Label>
						<Form.Select
							name='district'
							onChange={handleFormChange}
							value={formData.district}
							disabled={!formData.province}
						>
							<option value=''>Select one</option>
							{districts.map((option) => (
								<option key={option} value={option}>{option}</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group>
						<Form.Label>Municity</Form.Label>
						<Form.Select
							name='municity'
							onChange={handleFormChange}
							value={formData.municity}
							disabled={!formData.district}
						>
							<option value=''>Select one</option>
							{muniCities.map((option) => (
								<option key={option} value={option}>{option}</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group>
						<Form.Label>Barangay</Form.Label>
						<Form.Select
							name='barangay'
							onChange={handleFormChange}
							value={formData.barangay}
							disabled={!formData.municity}
						>
							<option value=''>Select one</option>
							{barangays.map((option) => (
								<option key={option} value={option}>{option}</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group>
						<Form.Label>Precinct</Form.Label>
						<Form.Select
							name='precinct'
							onChange={handleFormChange}
							value={formData.precinct}
							disabled={!formData.barangay}
						>
							<option value=''>Select one</option>
							{precincts.map((option) => (
								<option key={option} value={option}>{option}</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
			</Row>
			<Row className='mb-3'>
				<Col>
					<Form.Group>
						<Form.Label>Variable</Form.Label>
						<Form.Select
							name='variable_id'
							onChange={handleFormChange}
							value={formData.variable_id}
							disabled={(!formData.province && !formData.district && !formData.muniCity && !formData.barangay && !formData.precinct)}
						>
							<option value=''>Select one</option>
							{variables?.filter(variable => variable.kpi_type !== null).map((option) => (
								<option key={option.id} value={option.id}>
									{option.name?.toUpperCase()}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
				<Col>
					<Form.Group>
						<Form.Label>Variable Option</Form.Label>
						<Form.Select
							name='variable_option_id'
							onChange={handleFormChange}
							value={formData.variable_option_id}
							disabled={(!formData.variable_id)}
						>
							<option value=''>Select one</option>
							{options?.map((option) => (
								<option key={option.id} value={option.id}>
									{option.name?.toUpperCase()}
								</option>
							))}
						</Form.Select>
					</Form.Group>
				</Col>
			</Row>
		</>
	)
}

const KpiFormFields = ({ formData, handleFormChange, population, criteria, setCriteria, addCriteria, removeCriteria, handleCriteriaChange, mode }) => {
	return (
		<Row>
			<Col>
				<Form.Group as={Row} className='mb-3'>
				<Form.Label column sm={3} className='text-end'>KPI</Form.Label>
				<Col sm={9}>
					<Form.Control
						name='kpi_name'
						value={formData.kpi_name}
						onChange={handleFormChange}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className='mb-3'>
				<Form.Label column sm={3} className='text-end'>Start Date</Form.Label>
				<Col sm={9}>
					<Form.Control
						name='start_date'
						type='date'
						value={formData.start_date}
						onChange={handleFormChange}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className='mb-3'>
				<Form.Label column sm={3} className='text-end'>End Date</Form.Label>
				<Col sm={9}>
					<Form.Control
						name='end_date'
						type='date'
						value={formData.end_date}
						onChange={handleFormChange}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className='mb-3' controlId='formHorizontalEmail'>
				<Form.Label column sm={3} className='text-end'>
					Target Value
				</Form.Label>
				<Col sm={9}>
					<Form.Control
						name='target_value'
						value={formData.target_value}
						onChange={handleFormChange}
						type='number'
						min={0}
						max={population}
						step={1}
						placeholder='Input actual value (% value is auto-computed)'
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row} className='mb-3' controlId='formHorizontalEmail'>
				<Form.Label column sm={3} className='text-end'>
					Percentage Value
				</Form.Label>
				<Col sm={9}>
					<div className="input-group">
						<Form.Control
							name='percentage_value'
							value={formData.percentage_value}
							onChange={handleFormChange}
							type='number'
							min={0}
							max={100}
							step={0.1}
							placeholder='Input % value (actual value is auto-computed)'
						/>
						<span className="input-group-text">%</span>
					</div>
				</Col>
			</Form.Group>
		</Col>

			<Col>
				<CriteriaSection
					criteria={criteria}
					setCriteria={setCriteria}
					onAdd={addCriteria}
					onRemove={removeCriteria}
					onChange={handleCriteriaChange}
				/>
			</Col>
		</Row>
	)
}

export default KpiForm;
