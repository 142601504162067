function Chat(params) {
    const { chat } = params;

    const getChatGroupName = (chat) => {
        if (!chat) {
            return '';
        }

        if (chat.name !== null) {
            return chat.name;
        }

        let name = chat.members[0]['name'];

        if (chat.members.length > 1) {
            name = chat.members.map(member => member['name']).join(', ');
        }

        return name;
    };
    
    return(
        <section id={`communicate-chat-${chat?.id}`}>
            <h5>{getChatGroupName(chat)}</h5>
            <p>{chat?.last_message ? chat?.last_message.content?.substring(0, 25) : 'New Message'}</p>      
        </section>
    )
}

export default Chat;