import "./ManageRoles.css";
import { getRoleListAction } from "actions/roleActions";
import { useEffect, useState } from "react";
import { Card, Table, Button, Row, Col, Stack, Container, Badge } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { roleSelector } from "selectors/roleSelector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus, faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import RoleModal from "components/Modals/RoleModal/RoleModal";
import { getModules } from "helpers";

function ManageRoles() {
    const dispatch = useDispatch();
    const { roles } = useSelector(roleSelector);
    const [ roleList, setRoleList ] = useState([]);
    const [ showRoleModal, setShowRoleModal ] = useState({ role: {}, show: false });
    const newRole = {
        description: '',
        name: '',
        bitvalue: '00000000'
    }

    const handleShowRoleModal = (role) => {
        setShowRoleModal({ role: role, show: true });
    }

    const handleHideRoleModal = () => {        
        dispatch(getRoleListAction());
        setShowRoleModal({ role: {}, show: false });
    }

    const handleReset = () => {
        setRoleList([]);
        dispatch(getRoleListAction());
    }

    useEffect(() => {
        dispatch(getRoleListAction());
    }, [dispatch]);
    
    useEffect(() => {
        if (roles) {
            setRoleList(roles);
        }
    }, [roles]);

    return(
        <section id="manageRoles">
            <Container fluid>
                { showRoleModal.show && <RoleModal show={showRoleModal} role={showRoleModal.role} onHide={handleHideRoleModal} /> }
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Stack direction="horizontal" className="d-flex justify-content-between">
                                    <div>
                                        <span>Manage Role</span>{" "}
                                        <Badge bg="success" size="sm">{roleList.length}</Badge>
                                    </div>
                                    <div>
                                        <Button variant="success" size="sm" onClick={() => handleShowRoleModal({ role: newRole, show: true })}><FontAwesomeIcon icon={faPlus} /></Button>{" "}
                                        <Button variant="outline-primary" size="sm" onClick={handleReset}><FontAwesomeIcon icon={faRotateLeft} /></Button>
                                    </div>
                                </Stack>
                            </Card.Header>
                            <Card.Body className="wrapper">
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th width="2%">No.</th>
                                            <th width="40%">Name</th>
                                            <th width="20%">Description</th>
                                            <th width="30%">Permissions</th>
                                            <th width="8%">Actions</th>                                    
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            roleList.map((role, index) => {
                                                return(
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{role.name}</td>
                                                        <td>{role.description}</td>
                                                        <td>{getModules(role.bitvalue).join(', ')}</td>
                                                        <td>
                                                            <Stack gap={2} direction="horizontal" className="d-flex justify-content-center">
                                                                <Button variant="primary" size="sm" onClick={() => handleShowRoleModal(role)}><FontAwesomeIcon icon={faPencil} /></Button>
                                                            </Stack>
                                                        </td>
                                                    </tr>                                                
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>   
            </Container>         
        </section>
    )
}

export default ManageRoles;