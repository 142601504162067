import { Container } from 'react-bootstrap';
import ChatList from './ChatList';
import { Row, Col } from 'react-bootstrap';
import ActiveChat from './ActiveChat';
import Pusher from 'pusher-js';

function ChatLanding() {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_ID, { cluster: 'ap1' });    

    return(
        <section id="communicate-chat">
            <Container fluid>
            <Row>
                <Col lg="3">
                    <ChatList pusher={pusher}/>
                </Col>
                <Col lg="9">
                    <ActiveChat pusher={pusher}/>
                </Col>
            </Row>
            </Container>         
        </section>
    )
}

export default ChatLanding;