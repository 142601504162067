import { 
    GET_CHAT_LIST_REQUEST,
    GET_CHAT_LIST_SUCCESS,
    GET_CHAT_LIST_FAILED,
    GET_CHAT_MESSAGES_REQUEST,
    GET_CHAT_MESSAGES_SUCCESS,
    GET_CHAT_MESSAGES_FAILED,
    START_CHAT_REQUEST,
    START_CHAT_SUCCESS,
    START_CHAT_FAILED,
    SEND_CHAT_MESSAGE_REQUEST,
    SEND_CHAT_MESSAGE_SUCCESS,
    SEND_CHAT_MESSAGE_FAILED,
    SET_ACTIVE_CHAT
} from 'constants/actionTypes';
import Immutable from 'seamless-immutable';

const initialState = Immutable({
    chats: [],
    activeChat: null,
    messages: []
})

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case GET_CHAT_LIST_REQUEST:
            return {
                ...state,
                chats: []
            };
        case GET_CHAT_LIST_SUCCESS:
            return {
                ...state,
                chats: action.payload.chats
            };
        case GET_CHAT_LIST_FAILED:
            return {
                ...state,
                errors: action.payload.errors
            };
        case GET_CHAT_MESSAGES_REQUEST:
            return {
                ...state,
                messages: []
            };
        case GET_CHAT_MESSAGES_SUCCESS:
            return {
                ...state,
                messages: action.payload.messages
            };
        case GET_CHAT_MESSAGES_FAILED:
            return {
                ...state,
                errors: action.payload.errors
            };
        case START_CHAT_REQUEST:
            return {
                ...state
            };
        case START_CHAT_SUCCESS:
            return {
                ...state
            };
        case START_CHAT_FAILED:
            return {
                ...state,
                errors: action.payload.errors
            };
        case SEND_CHAT_MESSAGE_REQUEST:
            return {
                ...state
            };
        case SEND_CHAT_MESSAGE_SUCCESS:
            return {
                ...state
            };
        case SEND_CHAT_MESSAGE_FAILED:
            return {
                ...state,
                errors: action.payload.errors
            };        
        case SET_ACTIVE_CHAT:
            return {
                ...state,
                activeChat: action.payload.activeChat
            }
        default:
            return state;
    }
}