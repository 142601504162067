import getURL from "api/config";
import { POST } from "api/index";
import * as types from "constants/actionTypes";
import { getError } from "helpers";
import { persistor } from "store";

export const loginAction = (username, password) => async(dispatch) => {
    try {
        const url = getURL("LOGIN");
        const response = await POST(url, { username, password });
        const token = response?.data?.result?.access_token;
        const sessionUser = response?.data?.result?.user;
        const bitValue = response?.data?.result?.user?.role?.bitvalue;

        localStorage.setItem("access_token", token);
        localStorage.setItem("user", sessionUser);
        localStorage.setItem("bitValue", bitValue);

        dispatch({
            type: types.LOGIN_SUCCESS,
            payload: { sessionUser, userId: sessionUser.id }
        })
    } catch(error) {
        dispatch({
            type: types.LOGIN_FAILED,
            payload: {
                errors: getError(error)
            }
        })
    }
};

export function logoutAction() {
    return async function action(dispatch) {
        const url = getURL("LOGOUT");
        const request = POST(url);    
    
        request.then(() => {
            localStorage.removeItem("access_token");
            localStorage.removeItem("user");
            persistor.purge();
            window.location.href = "/login";

            dispatch({
                type: types.LOGOUT_SUCCESS
            })
        }, (error) => {
            dispatch({
                type: types.LOGOUT_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        });
    }    
};

export function forgotPasswordAction(email) {
    return function action(dispatch) {
        const url = getURL("FORGOT_PASSWORD");
        const request = POST(url, { email });

        request.then(() => {
            dispatch({
                type: types.FORGOT_PASSWORD_SUCCESS
            })
        }, (error) => {
            dispatch({
                type: types.FORGOT_PASSWORD_FAILED,
                payload: {
                    errors: getError(error)
                }
            })
        })
    };
};