import './RoleModal.css';
import { Modal, Form, Button, Stack, Container, Alert } from "react-bootstrap";
import { MODULE_LIST } from "constants/common";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from 'react-redux';
import { addRoleAction, updateRoleAction } from 'actions/roleActions';

function RoleModal({ show, onHide, role }) {
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        name: role.name,
        description: role.description,
        bitvalue: role.bitvalue || '00000000',
        is_active: 'true'
    });
    const handleBitValueChange = (e, index) => {
        const { checked } = e.target;
        const bitValueArray = formData.bitvalue.split('');
        bitValueArray[index] = checked ? '1' : '0';
        setFormData({ ...formData, bitvalue: bitValueArray.join('') });
    }

    const handleSubmit = () => {
        if (role?.id) {
            dispatch(updateRoleAction(role.id, formData));
        } else {
            dispatch(addRoleAction(formData));
        }
        onHide();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    }

    return(
        <Modal id="roleModal" show={show} onHide={onHide}>
            <Modal.Header closeButton><Stack direction="horizontal" gap={2}><span>Role Profile</span></Stack></Modal.Header>
            <Modal.Body>
                <Container>
                <Stack gap={2}>
                    <Form.Group>
                        <Form.Label>Role Name</Form.Label>
                        <Form.Control name="name" type="text" placeholder="Enter role name" defaultValue={formData.name} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Role Description</Form.Label>
                        <Form.Control name="description" type="text" placeholder="Enter role description" defaultValue={formData.description} onChange={handleChange} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check
                            type="checkbox"
                            id="is_active"
                            label="Enabled"
                            checked={formData.is_active}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group>
                        <Alert variant="info">
                            <p>
                                <FontAwesomeIcon icon={faInfoCircle} />{" "}
                                <span>Modules Access</span>
                                <br />
                                <small className="text-muted">Select the modules that the role will have access to.</small>
                            </p>
                        </Alert>
                         <Stack gap={2}>
                            {
                                MODULE_LIST.map((module, index) => (
                                <Form.Group key={index}>
                                    <Form.Check
                                        type="checkbox"
                                        id={`module-${index}`}
                                        label={module}
                                        inline
                                        checked={formData.bitvalue[index] === '1'}
                                        onChange={(e) => handleBitValueChange(e, index)}
                                    />
                                </Form.Group>
                                ))
                            }
                        </Stack>
                    </Form.Group>
                </Stack>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleSubmit}>Save</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default RoleModal;