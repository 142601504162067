import { 
    DISTRICT_LIST_LOAD_FAILED,
    DISTRICT_LIST_LOAD_REQUEST,
    DISTRICT_LIST_LOAD_SUCCESS
} from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    districts: [],
})

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case DISTRICT_LIST_LOAD_REQUEST:
            return {
                ...state,
                districts: []
            };
        case DISTRICT_LIST_LOAD_SUCCESS:
            return {
                ...state,
                districts: action.payload.districts
            };
        case DISTRICT_LIST_LOAD_FAILED:
            return {
                ...state,
                errors: action.payload.errors
            };
        default:
            return state;
    }
}