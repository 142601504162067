import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getVariableAction } from 'actions/variableActions';
import { variableSelector } from 'selectors/variableSelector';

export function useVariableOptions(variable_id) {
  const dispatch = useDispatch();
  const { variable, variableError } = useSelector(variableSelector);

  useEffect(() => {
    dispatch(getVariableAction(variable_id));
  }, [dispatch, variable_id]);

  return {
    options: variable?.options,
    optionsError: variableError
  };
};
