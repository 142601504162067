import getURL from "api/config";
import { GET } from "api/index";
import * as types from "constants/actionTypes";
import { getError } from "helpers";

export function getDistrictListAction() {
    return function action(dispatch) {
        const url = getURL("GET_DISTRICTS");
        const request = GET(url);

        dispatch({
            type: types.DISTRICT_LIST_LOAD_REQUEST,
            payload: { districts: [] }
        });

        request.then((response) => {
            const districts = response?.data?.result || [];

            dispatch({
                type: types.DISTRICT_LIST_LOAD_SUCCESS,
                payload: { districts }
            })
        }, (error) => {
            dispatch({
                type: types.DISTRICT_LIST_LOAD_FAILED,
                payload: {
                    errors: getError(error)
                }
            });
        });   
    };
};
