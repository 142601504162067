import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { deleteUploadAction, getUploadListAction, uploadAction } from "actions/uploadActions";
import { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, FormControl, Row, Stack, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { uploadSelector } from "selectors/uploadSelector";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "components/Modals/ConfirmationModal/ConfirmationModal";

function UploadList() {
    const dispatch = useDispatch();
    const [uploadList, setUploadList] = useState([]);
    const { upload, uploads } = useSelector(uploadSelector);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [deleteableUploadId, setDeleteableUploadId] = useState(null);
    const uploadRef = useRef();
    
    const handleAddUpload = () => {
        if (uploadRef.current) {
            uploadRef.current.click();
        }
    };

    const handleDeletePrompt = (id) => {
        setDeleteableUploadId(id);
        setShowConfirmationModal(true);
    }

    const handleDeleteUpload = (id) => {
        dispatch(deleteUploadAction(id));
        setShowConfirmationModal(false);
        setDeleteableUploadId(null);
        dispatch(getUploadListAction());
    }

    const handleCancelDelete = () => {
        setDeleteableUploadId(null);
        setShowConfirmationModal(false);
    }

    const uploadFile = () => {
        const formData = new FormData();
        formData.append('file', uploadRef.current.files[0]);
        dispatch(uploadAction(formData));
    };

    useEffect(() => {
        dispatch(getUploadListAction());
    }, [dispatch, upload]);

    useEffect(() => {
        if (uploads.length > 0) {
            setUploadList(uploads);
        }
    }, [uploads]);

    return (
        <section id="upload-list">
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <Card.Header>
                                <Stack direction="horizontal" className="d-flex justify-content-between">
                                    Uploads
                                    <Button variant="success" size="sm" onClick={handleAddUpload}><FontAwesomeIcon icon={faPlus} /></Button>{" "}
                                </Stack>
                            </Card.Header>
                            <Card.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <th>Filename</th>
                                        <th>Type</th>
                                        <th>Action</th>
                                    </thead>
                                    <tbody>
                                        {
                                            uploadList.map((upload) => (
                                                <tr>
                                                    <td>
                                                        <a rel="noreferrer" target="_blank" href={upload.presigned_url}>{upload.original_filename}</a>
                                                    </td>
                                                    <td>{upload.mime_type}</td>
                                                    <td><Button variant="danger" onClick={() => handleDeletePrompt(upload.id)}><FontAwesomeIcon icon={faTrash} /></Button></td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                            </Table>
                            <ConfirmationModal show={showConfirmationModal} message="This will permanently delete the file. Are you sure?" onHide={() => handleCancelDelete()} onConfirm={() => handleDeleteUpload(deleteableUploadId)} />
                            <FormControl hidden type="file" ref={uploadRef} onChange={(e) => uploadFile(e)} />
                            </Card.Body>
                        </Card>                    
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default UploadList;