import { 
    UPLOAD_FILE_SUCCESS,
    UPLOAD_LIST_LOAD_FAILED,
    UPLOAD_LIST_LOAD_REQUEST,
    UPLOAD_LIST_LOAD_SUCCESS,
    UPLOAD_FILE_FAILED
} from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    uploads: [],
})

export default function reduce(state = initialState, action) {
    switch (action.type) {
        case UPLOAD_LIST_LOAD_REQUEST:
            return {
                ...state,
                uploads: []
            };
        case UPLOAD_LIST_LOAD_SUCCESS:
            return {
                ...state,
                uploads: action.payload.uploads
            };
        case UPLOAD_LIST_LOAD_FAILED:
            return {
                ...state,
                errors: action.payload.errors
            };
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                upload: action.payload.upload
            };
        case UPLOAD_FILE_FAILED:
            return {
                ...state,
                errors: action.payload.errors
            };
        default:
            return state;
    }
}