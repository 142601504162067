import { Modal, Button, Stack } from "react-bootstrap";

function ConfirmationModal({ show, message, onHide, onConfirm }) {

    return(
        <Modal id="roleModal" show={show} onHide={onHide}>
            <Modal.Header closeButton><Stack direction="horizontal" gap={2}><span>Are you sure?</span></Stack></Modal.Header>
            <Modal.Body>
                {message}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onConfirm}>Proceed</Button>
                <Button variant="secondary" onClick={onHide}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default ConfirmationModal;