import { Modal, Button, Form, Stack, FormGroup, FormControl, FormLabel } from "react-bootstrap";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { startChat } from "actions/chatActions";
import AsyncSelect from 'react-select/async';
import { GET } from 'api/index';
import getURL from "api/config";

function NewChatModal({ show, onClose }) {
    const [formData, setFormData] = useState({});
    const dispatch = useDispatch();

    const loadOptions = async(inputValue) => {
        const url = getURL("GET_USERS");
        const request = GET(url, {}, {}, { name: inputValue });
        let options = [];
        await request.then((response) => {            
            options = response.data.data.data;            
        });

        return options.map((option) => ({ label: option.name, value: option.id }));
    }

    const handleChange = (e) => {
        setFormData((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = async() => {
        dispatch(startChat(formData));
        onClose();
    };

    return(
        <Modal id="roleModal" show={show} onHide={onClose}>
            <Modal.Header closeButton><Stack direction="horizontal" gap={2}><span>Start New Chat</span></Stack></Modal.Header>
            <Modal.Body>
                <Form>
                    <FormGroup>
                        <FormLabel>Group name (optional)</FormLabel>
                        <FormControl name='name' placeholer='Group A' onChange={handleChange} />
                    </FormGroup>

                    <FormGroup>
                        <FormLabel>Chat members</FormLabel>
                        <AsyncSelect
                            isMulti
                            cacheOptions
                            loadOptions={loadOptions}
                            onChange={(selectedIds) => handleChange({ target: { name: 'member_ids', value: selectedIds.map((sId) => (sId.value)) } })}
                            placeholder='Type to search...'
                        />
                    </FormGroup>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="success" onClick={handleSubmit}>Create</Button>
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
            </Modal.Footer>
        </Modal>
    )
};

export default NewChatModal;