import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import provinceReducer from "reducers/provinceReducer";
import publicReducer from "reducers/publicReducer";
import sessionReducer from "reducers/sessionReducer";
import storage from "redux-persist/lib/storage";
import userReducer from "reducers/userReducer";
import variableReducer from "reducers/variableReducer";
import voterReducer from "reducers/voterReducer";
import municipalityReducer from "reducers/municipalityReducer";
import roleReducer from "reducers/roleReducer";
import sidebarReducer from "reducers/sidebarReducer";
import barangayReducer from "reducers/barangayReducer";
import precinctReducer from "reducers/precinctReducer";
import geopoliticalUnitReducer from "reducers/geopoliticalUnitReducer";
import kpiReducer from "reducers/kpiReducer";
import districtReducer from "reducers/districtReducer";
import chatReducer from "reducers/chatReducer";
import uploadReducer from "reducers/uploadReducer";

const rootPersistConfig = {
    key: "root",
    storage,
    whitelist: ["session"]
}

const rootReducer = combineReducers({
    barangay: barangayReducer,
    district: districtReducer,
    geopoliticalUnit: geopoliticalUnitReducer,
    municipality: municipalityReducer,
    precinct: precinctReducer,
    province: provinceReducer,
    public: publicReducer,
    role: roleReducer,
    session: sessionReducer,
    sidebar: sidebarReducer,
    variable: variableReducer,
    voter: voterReducer,
    user: userReducer,
    kpi: kpiReducer,
    chat: chatReducer,
    upload: uploadReducer
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoreActions: ["persist/PERSIST", "persist/HYDRATE"],
            },
        }),
});

export const persistor = persistStore(store);