import "./FilterUsers.css";
import { Button, Modal, Container, Form, Stack, Row, Col } from "react-bootstrap";
import { useState, useEffect } from "react";
import { useGeopoliticalUnits } from "hooks/useGeopoliticalUnits";
import { SEXES } from "constants/common";
import { useVariables } from "hooks/useVariables";
import { useDispatch, useSelector } from "react-redux";
import { getVariableAction } from "actions/variableActions";
import { variableSelector } from "selectors/variableSelector";
import { v4 as uuidv4 } from "uuid";
import VariableFilter from "../../ManageVoters/FilterVoters/VariableFilter";

function FilterUsers({ show, onHide }) {
    const dispatch = useDispatch();
    const [filters, setFilters] = useState({});
    const { provinces, districts, muniCities, precincts, barangays } = useGeopoliticalUnits({
        province: filters.provinces,
        district: filters.districts,
        muniCity: filters.municities,
        barangay: filters.barangays,
        precinct: filters.precincts
    });
    const { variables } = useVariables();
    const { variable } = useSelector(variableSelector);
    const [selectedVariables, setSelectedVariables] = useState([]);

    const handleFilterChange = (e) => {
        const { name, value } = e.target;
        setFilters({ ...filters, [name]: value });
    };

    const handleClose = () => {
        setFilters({});
        onHide();
    };

    const addVariableFilter = (e) => {
        dispatch(getVariableAction(e.target.value))        
    };

    useEffect(() => {
        setSelectedVariables((prev) => prev.concat(variable))
    }, [variable]);

    return (
        <Modal id="filterUsers" show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>Filter Users</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container>
                    <Stack gap={2}>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={filters.name || ''}
                                    placeholder="Name"
                                    onChange={handleFilterChange}
                                />
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Sex</Form.Label>
                                <Form.Select
                                    name="sex"
                                    value={filters.sex || ''}
                                    onChange={handleFilterChange}
                                >
                                    <option value="">All</option>
                                    {
                                        SEXES.map((option) => (
                                            <option value={option === 'Male' ? 'M' : 'F'}>{option}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Birthdate</Form.Label>
                                <Form.Control
                                    name="birthdate"
                                    type="date"
                                    value={filters.birthdate || ''}
                                    onChange={handleFilterChange}
                                />
                            </Form.Group>
                        </Row>
                        <Form.Group>
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                name="address"
                                value={filters.address || ''}
                                placeholder="Address"
                                onChange={handleFilterChange}
                            />
                        </Form.Group>

                        <Form.Group>
                            <Form.Label>Provinces</Form.Label>
                            <Form.Select
                                name="provinces"
                                value={filters.provinces || ''}
                                onChange={handleFilterChange}
                            >
                                <option value="">All</option>
                                {
                                    provinces.map((option) => (
                                        <option value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Row>
                            <Form.Group as={Col}>
                                <Form.Label>Districts</Form.Label>
                                <Form.Select
                                    name="districts"
                                    value={filters.districts || ''}
                                    onChange={handleFilterChange}
                                    disabled={!filters.provinces}
                                >
                                    <option value="">{filters.provinces ? 'All' : 'Select a province first'}</option>
                                    {
                                        districts.map((option) => (
                                            <option value={option}>{option}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label> Municipality/City</Form.Label>
                                <Form.Select
                                    name="municities"
                                    value={filters.municities || ''}
                                    onChange={handleFilterChange}
                                    disabled={!filters.districts}
                                >
                                    <option value="">{filters.districts ? 'All' : 'Select a district first'}</option>
                                    {
                                        muniCities.map((option) => (
                                            <option value={option}>{option}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Barangays</Form.Label>
                                <Form.Select
                                    name="barangays"
                                    value={filters.barangays || ''}
                                    onChange={handleFilterChange}
                                    disabled={!filters.municities}
                                >
                                    <option value="">{filters.municities ? 'All' : 'Select a municipality/city first'}</option>
                                    {
                                        barangays.map((option) => (
                                            <option value={option}>{option}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                            <Form.Group as={Col}>
                                <Form.Label>Precincts</Form.Label>
                                <Form.Select
                                    name="precincts"
                                    value={filters.precincts || ''}
                                    onChange={handleFilterChange}
                                    disabled={!filters.barangays}
                                >
                                    <option value="">{filters.barangays ? 'All' : 'Select a barangay first'}</option>
                                    {
                                        precincts.map((option) => (
                                            <option value={option}>{option}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Form.Group>
                        </Row>
                        <Form.Group>
                            <Form.Label>Add Variables</Form.Label>
                            <Form.Select onChange={(e) => addVariableFilter(e)}>
                                <option>Select a variable to add filter</option>
                                {
                                    variables?.map((variable) => (
                                        <option key={variable.variable_id} value={variable.variable_id}>{variable.name}</option>
                                    ))
                                }
                            </Form.Select>
                        </Form.Group>
                        <Row>
                            {
                                selectedVariables?.map((variable) => (
                                    variable && <Col md={3} key={uuidv4()}>
                                        <VariableFilter key={uuidv4()} variable={variable} filters={filters} onChange={handleFilterChange} />
                                    </Col>
                                ))
                            }
                        </Row>
                    </Stack>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => onHide(filters)}>Filter</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FilterUsers;