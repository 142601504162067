import { LOGIN_FAILED, LOGIN_SUCCESS, LOGOUT_FAILED, LOGOUT_SUCCESS, FORGOT_PASSWORD_FAILED, FORGOT_PASSWORD_SUCCESS } from "constants/actionTypes";
import Immutable from "seamless-immutable";

const initialState = Immutable({
    sessionUser: null,
    userId: null,
    error: null
})

export default function reduce (state = initialState, action) {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                sessionUser: action.payload.sessionUser,
                userId: action.payload.userId,
                error: null
            };
        case LOGIN_FAILED:
            return {
                ...state,
                sessionUser: null,
                error: action.payload.errors
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                sessionUser: null,
                error: null
            };
        case LOGOUT_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                error: null
            };
        case FORGOT_PASSWORD_FAILED:
            return {
                ...state,
                error: action.payload.errors
            };
        default:
            return state;
    }
}

