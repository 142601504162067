import "./Analyze.css";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useGeopoliticalUnits } from "hooks/useGeopoliticalUnits";
import { v4 as uuidv4 } from "uuid";
import { getVariableAction, getVariableListAction } from "actions/variableActions";
import { useDispatch, useSelector } from "react-redux";
import { variableSelector } from "selectors/variableSelector";
import { useCallback, useEffect, useState } from "react";
import { getVoterDistribution } from "actions/voterActions";
import { voterSelector } from "selectors/voterSelector";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, LabelList } from 'recharts';
import { INTERVALS } from "constants/common";

function Analyze() {
    const dispatch = useDispatch();
    const getDefaultDate = () => {
        const curr = new Date();
        const date = curr.toISOString().substring(0, 10);
        return date;
    }
    const [options, setOptions] = useState({
        start_date: getDefaultDate(),
        end_date: getDefaultDate()
    });
    const {
        provinces,
        districts,
        muniCities,
        barangays,
        precincts
    } = useGeopoliticalUnits(options);
    const { variables, variable } = useSelector(variableSelector);
    const { overallDistribution, responseMsg } = useSelector(voterSelector);
    const barColors = ['#0A369D', '#4472CA', '#5E7CE2', '#92B4F4'];

    useEffect(() => {
        dispatch(getVariableListAction({is_active: true}))
    }, [dispatch])

    useEffect(() => {
        if (options.variable_id) {
            dispatch(getVariableAction(options.variable_id))
        }
    }, [options.variable_id, dispatch])


    const handleChange = (e) => {
        console.log(e)
        setOptions((prev) => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = useCallback(() => {
        dispatch(getVoterDistribution(options, {}));
    }, [options, dispatch]);

    const generateButtonEnabled = () => {
        return options.variable_id;
    };

    const handleReset = () => {
        setOptions({});
    }

    const formatChartData = (data) => {
        const { total, ...options } = data;
        return Object.keys(options).map((key) => [key, data[key]]);
    };


    return (
        <section id="analyze">
            <Card>
                <Card.Header>
                    Generate Graphs
                </Card.Header>
                <Card.Body>
                    <Form>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Variable</Form.Label>
                                    <Form.Select name="variable_id" onChange={handleChange} value={options.variable_id || variable?.id}>
                                        <option value="">Select one</option>
                                        {
                                            variables?.map((option) => (
                                                <option key={option.id} value={option.id}>{option.name}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Interval</Form.Label>
                                    <Form.Select name="interval" onChange={handleChange} value={options.interval}>
                                        <option value="">Select one</option>
                                        {
                                            INTERVALS.map((option) => (
                                                <option key={uuidv4()} value={option}>{option}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Province</Form.Label>
                                    <Form.Select name="province" onChange={handleChange} value={options.province}>
                                        <option value="">Select Province</option>
                                        {
                                            provinces.map((province) => (
                                                <option key={uuidv4()} value={province}>{province}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select District</Form.Label>
                                    <Form.Select name="district" disabled={!options.province} onChange={handleChange} value={options.district}>
                                        <option value="">Select District</option>
                                        {
                                            districts.map((district) => (
                                                <option key={uuidv4()} value={district}>{district}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Municipality/City</Form.Label>
                                    <Form.Select name="muniCity" disabled={!options.district} onChange={handleChange} value={options.muniCity}>
                                        <option value="">Select Municipality/City</option>
                                        {
                                            muniCities.map((muniCity) => (
                                                <option key={uuidv4()} value={muniCity}>{muniCity}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Barangay</Form.Label>
                                    <Form.Select name="barangay" disabled={!options.muniCity} onChange={handleChange} value={options.barangay}>
                                        <option value="">Select Barangay</option>
                                        {
                                            barangays.map((barangay) => (
                                                <option key={uuidv4()} value={barangay}>{barangay}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Select Precinct</Form.Label>
                                    <Form.Select name="precinct" disabled={!options.barangay} onChange={handleChange} value={options.precinct}>
                                        <option value="">Select Precinct</option>
                                        {
                                            precincts.map((precinct) => (
                                                <option key={uuidv4()} value={precinct}>{precinct}</option>
                                            ))
                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Start Date</Form.Label>
                                    <Form.Control type="date" name="start_date" onChange={handleChange} defaultValue={options.start_date || getDefaultDate()} />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>End Date</Form.Label>
                                    <Form.Control type="date" name="end_date" onChange={handleChange} defaultValue={options.end_date || getDefaultDate()} />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="success" disabled={!generateButtonEnabled()} onClick={() => handleSubmit()}>Generate</Button>
                                <span>&nbsp;</span>
                                <Button variant="secondary" onClick={() => handleReset()}>Reset</Button>
                            </Col>
                        </Row>
                        <hr />
                        {responseMsg && <Alert variant="warning">{responseMsg}</Alert>}
                        {variable && <h1 className='text-center'>{variable.label}</h1>}
                        {overallDistribution &&
                            <div className="charts">
                                <ResponsiveContainer>
                                    <BarChart
                                        layout="vertical"
                                        data={formatChartData(overallDistribution)}
                                        margin={{
                                            top: 5,
                                            right: 30,
                                            left: 20,
                                            bottom: 5,
                                        }}
                                    >
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis type="number" />
                                        <YAxis type="category" dataKey={(data) => data[0]} />
                                        <Tooltip />
                                        <Bar dataKey={(data) => data[1]} height={100}>
                                            {
                                                Object.keys(overallDistribution).map((_key, index) => (
                                                    <Cell key={uuidv4()} fill={barColors[index % 4]} />
                                                ))
                                            }
                                            <LabelList dataKey={(data) => data[1]} position="right" />
                                        </Bar>
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        }
                    </Form>
                </Card.Body>
            </Card>
        </section>
    )
}

export default Analyze;
