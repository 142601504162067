import { Card, Button, ListGroup, Stack } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getChats } from 'actions/chatActions';
import { chatSelector } from 'selectors/chatSelector';
import Chat from 'pages/Communicate/Chat/Chat';
import { SET_ACTIVE_CHAT } from 'constants/actionTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import NewChatModal from './NewChatModal';
import { sessionSelector } from 'selectors/sessionSelector';


function ChatList(params) {
    const { pusher } = params;   
    const { chats } = useSelector(chatSelector);
    const [showNewChatModal, setNewChatModal] = useState(false);
    const [userChats, setUserChats] = useState([]);
    const dispatch = useDispatch();
    const { sessionUser } = useSelector(sessionSelector);
    const channel = pusher.subscribe(sessionUser.id);

    const handleChatSelect = (chat) => {
        dispatch({
            type: SET_ACTIVE_CHAT,
            payload: {
                activeChat: chat
            }
        });
    };

    const renderChatList = () => {
        return userChats.map((chat) => (
            <ListGroup.Item key={`${chat.id}-chat`} onClick={() => handleChatSelect(chat)}>
                <Chat chat={chat} />
            </ListGroup.Item>
        ))
    };

    useEffect(() => {
        dispatch(getChats());
        channel.bind('chat', function(data) {
            setUserChats((prev) => [...prev, data.chat]);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setUserChats(chats);
    }, [chats]);

    return(
        <section id="communicate-chat-list">      
            <NewChatModal show={showNewChatModal} onClose={() => setNewChatModal(null)} />
            <Card>
                <Card.Header>
                    <Stack className='w-100 justify-content-between' direction='horizontal'>
                        Chats
                        <Button variant='success' onClick={() => setNewChatModal(true)}><FontAwesomeIcon icon={faPlus}/></Button>
                    </Stack>
                </Card.Header>
                <Card.Body>
                    <ListGroup variant='flush'>
                        {renderChatList()}
                    </ListGroup> 
                </Card.Body>                       
            </Card>                  
        </section>
    )
}

export default ChatList;